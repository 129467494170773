<template>
  <div class="answer">
    <div id="a-header-container">
      <Header></Header>
    </div>
    <div id="a-main">
      <div id="a-gif-text-container">
        <div id="a-gif">
          <img src="../../assets/UN_Women_Spurning06.gif" alt="">
        </div>
        <div id="a-answer-text-container">
          <div id="a-user-answer">
            <div>
              {{ getAnswerStatus }}
            </div>
          </div>
          <div id="a-text">
            <p id="a-answer-text1">
              {{ getQuestion.answerText }}
            </p>
            <p id="a-answer-text2">
              {{ getQuestion.answerText2 }}
            </p>
          </div>
          <div id="a-border-line"></div>
          <div id="a-button-container">
            <button @click="next" class="a-button"><div>Næsta spurning</div></button>
          </div>
        </div>
      </div>
      <div id="a-footer-container">
        <footer-mobile></footer-mobile>
      </div>
    </div>
    <div id="a-footer-wide">
      <!-- <div id="a-button-container-wide">
          <button @click="next" class="a-button">Næsta spurning</button>
      </div> -->
      <div id="a-footer-container-wide">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../Header.vue'
import Footer from '../Footer.vue'
import FooterMobile from '../FooterMobile.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations([
      'setCurrentQuestion',
      'setIsProgressBar'
      ]),
    next() {
      this.$router.push('../Question7');
    }
  },
  components: {
    Header,
    Footer,
    FooterMobile
  },
  computed: {
    ...mapGetters([
      'getQuestion',
      'getAnswerStatus'
      ])
  },
  created() {
    this.setCurrentQuestion(5);
    this.setIsProgressBar(true);
  }
}
</script>

<style scoped>


</style>