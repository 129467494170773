<template>
  <div class="question-container">
    <div id="q-header-container">
      <Header></Header>
    </div>
    <div id="q-main">
      <div id="question">
        <p>
          {{ getQuestion.question }}
        </p>
      </div>
      <div id="q-border-line"></div>
      <div id="q-button-container">
        <button v-for="(option, index) in getQuestion.options" class="q-button" @click="score(index)" v-bind:key="index">
          <span> {{ option }} </span>
        </button>
      </div>
    </div>
    <div id="q-footer-mobile">
      <footer-mobile></footer-mobile>
    </div>
    <div id="q-footer-desktop">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '../Header.vue'
import FooterMobile from '../FooterMobile.vue'
import Footer from '../Footer.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations([
      'setScore',
      'setCurrentQuestion',
      'setIsProgressBar'
    ]),
    score(index) {
      this.setScore(index);
      this.$router.push('../Answer8');
    }
  },
  components: {
    Header,
    FooterMobile,
    Footer
  },
  computed: {
    ...mapGetters(['getQuestion'])
  },
  created() {
    this.setCurrentQuestion(7);
    this.setIsProgressBar(true);
  }
}
</script>

<style scoped>

.question-container {
  background-image: url('../../assets/09.jpg');
  background-size: auto 110%;
  height: 110%;
  background-position: 70% 0%;
}

#q-button-container {
  width: 100%;
}

.q-button {
  min-height: 60px;
  width: 100%;
  padding: 0px 5px;
}

#question {
  align-items: flex-end;
  height: 35%;
  padding-bottom: 20px;
}

.q-button {
  line-height: 18px;
  height: 70px;
}

#q-button-container {
  height: 65%;
  justify-content: flex-start;
  padding-top: 20px;
}

#q-main {
  justify-content: flex-start;
}

@media (min-width: 1024px) {
  .question-container {
    padding: 40px;
    background-size: cover;
    height: 100%;
    min-height: 760px;
  }

  #question {
    height: 40%;
    justify-content: flex-end;
  }

  #question p {
    font-size: 25px;
    line-height: 36px;
  }

  #q-button-container {
    width: 450px;
    height: 57%;
  }

  .q-button {
    width: 100%;
    padding: 0px 5px;
  }

  #q-footer-desktop {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }

  #q-footer-mobile {
    display: none;
  }
}
</style>