<template>
  <div id="footer-mobile-container">
    <div id="deila-mobile">
      <Deila></Deila>
    </div>
    <div id="un-page">
      <a href="https://unwomen.is/verkefnin/loftslagsbreytingar/" style="text-decoration:none" target="_blank"><div>UN Women Ísland heimasíða</div></a>
    </div>
  </div>
</template>

<script>
import Deila from './Deila.vue'

export default {
  name: 'FooterMobile',
  components: {
    Deila
  }
}
</script>

<style scoped>

#footer-mobile-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%
}

#deila-mobile {
  padding-bottom: 10px;
}

#un-page {
  border-bottom: 2px solid white;
}

#un-page a{
  color: white;
  display: flex;
  align-items: flex-end;

}

#un-page a div {
  margin-bottom: 2px;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  vertical-align: bottom;
}

</style>
