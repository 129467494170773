<template>
<div id="share-container">
  <div id="facebook">
    <a href="https://www.facebook.com/sharer/sharer.php?u=https://loftslagsbreytingar.unwomen.is/" target="_blank">
      <img src="../assets/icon-fb.png" alt="U.N. Logo">
    </a>
  </div>
  <div id="twitter">
    <a href="https://twitter.com/intent/tweet?url=&text=Hvernig%20bitna%20loftlagsbreytingar%20%C3%A1%20konum%3F%20Taktu%20pr%C3%B3fi%C3%B0%20og%20sj%C3%A1%C3%B0u%20hvar%20%C3%BE%C3%BA%20stendur.%20%20https://loftslagsbreytingar.unwomen.is" target="_blank">
      <img src="../assets/icon-twitter.png" alt="U.N. Logo">
    </a>
  </div>
</div>
</template>

<script>
  export default {
  }
</script>

<style scoped>

#share-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

#facebook {
  padding: 0px 20px;
}

#twitter {
  padding-left: 20px;
}

@media (min-width: 1024px) {
  #share-container {
    justify-content: flex-end;
  }
}

</style>