<template>
  <div class="final">
    <div id="header-container">
      <Header></Header>
    </div>
    <div id="main">
      <div id="final-score">
        <div id="final-title">
          <h1>
            {{ getFinalScore }} af 9 - {{ getFinalResult.percent }}
          </h1>
        </div>
        <div id="final-border-line"></div>
        <div id="text">
          <p>
            {{ getFinalResult.text }}
          </p>
          <p class="bold">
            Deila á:
          </p>
        </div>
        <deila-blatt></deila-blatt>
      </div>
      <!-- <div id="final-signup-text">
        Vilt þú vinna þriggja rétta kvöldverð, hótelgistingu og morgunverð í boði Íslandshótel?
      </div>
      <div id="final-button-container">
        <a href="https://unwomen.is/gjafaleikur-islandshotel/">
          <button class="final-button">
            <div>
              JÁ!
            </div>
          </button>
        </a>
      </div> -->
    </div>
    <div id="final-footer-mobile">
      <footer-mobile></footer-mobile>
    </div>
    <div id="final-footer-container">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'
import FooterMobile from './FooterMobile.vue'
import DeilaBlatt from './DeilaBlatt.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  components: {
    Header,
    Footer,
    FooterMobile,
    DeilaBlatt
  },
  computed: {
    ...mapGetters([
      'getFinalScore',
      'getFinalResult'
      ])
  },
  methods: {
    ...mapMutations([
      'setIsProgressBar'
    ])
  },
  created() {
    this.setIsProgressBar(false);
  },
  beforeMount() {
    window.scrollTo(0, 0);
  }
}
</script>

<style scoped>

.final {
  margin: 0;
  padding: 20px;
  width: 100%;
  background-color: #0397d6;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}

/* Header  */

#header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 62px;
}

/* Main  */

#main {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
}

#final-score {
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: #0397d6;
  border-radius: 8px;
}

#final-border-line {
  padding-top: 10px;
  border-bottom: solid #0397d6 2px;
  width: 94px;
}

#text {
  padding-top: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 85%;
  max-width: 480px;
  flex-direction: column;
}

#text p {
  font-size: 16px;
}

#final-title h1{
  font-size: 28px;
  font-weight: 600;
}

.bold {
  font-weight: 600;
}

#final-signup-text {
  font-size: 16px;
  padding-top: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 85%;
  max-width: 480px;
  color: white;
  font-weight: 600;
}

#final-button-container {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 40%;
  padding-bottom: 40px;
}

.final-button {
  font-family: 'Lato', sans-serif;
  font-weight: 600;
  border: solid 2px #0397d6;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: #0397d6;
  border-radius: 10px;
  height: 50px;
  width: 190px;
  align-items: center;
}

.final-button {
  text-decoration: none;
  color: #0397d6;
  display: inline-block;
}

.final-button:hover {
  background-color: #0397d6;
  color: white;
  border: solid 2px white;
}

.final-button:focus {
  outline: none;
}

/* footer */
#footer-container {
  width: 100%;
  height: 20%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

#final-footer-container {
  display: none;
}


@media (min-width: 1024px) {

  #text p {
    font-size: 18px;
  }

  #final-signup-text {
    font-size: 18px;
  }

  .final {
    padding: 40px;
  }

  #final-footer-mobile {
    display: none;
  }

  #final-footer-container {
    width: 100%;
    display: flex;
    align-items: center;
    height: 20vh;
    flex-direction: column;
  }
}


</style>