<template>
<div id="q-background">
  <div class="question-container">
    <div id="q-header-container">
      <Header></Header>
    </div>
    <div id="q-main">
      <div id="question">
        <p>
          {{ getQuestion.question }}
        </p>
      </div>
      <div id="q-border-line"></div>
      <div id="q-button-container">
        <button v-for="(option, index) in getQuestion.options" class="q-button" @click="score(index)" v-bind:key="index">
          <div> {{ option }} </div>
        </button>
      </div>
    </div>
    <div id="q-footer-mobile">
      <footer-mobile></footer-mobile>
    </div>
    <div id="q-footer-desktop">
      <Footer></Footer>
    </div>
  </div>
</div>
</template>

<script>
import Header from '../Header.vue'
import FooterMobile from '../FooterMobile.vue'
import Footer from '../Footer.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations([
      'setScore',
      'setCurrentQuestion',
      'setIsProgressBar'
      ]),
    score(index) {
      this.setScore(index);
      this.$router.push('../Answer1');
    }
  },
  components: {
    Header,
    FooterMobile,
    Footer
  },
  computed: {
    ...mapGetters(['getQuestion'])
  },
  created() {
    this.setCurrentQuestion(0);
    this.setIsProgressBar(true);
  }
}
</script>

<style>

/* This is a global style that applies to all Question components */
#q-background {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100vw;
  background-image: url('../../assets/02.jpg');
  background-size: cover;
  background-position: 15% 0%;
}

.question-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

/* Header  */

#q-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 62px;
}


/* Main */

#q-main {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  max-width: 720px;
}

#question {
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: white;
  height: 50%;
  width: 100%;
  max-width: 1000px;
  padding-bottom: 20px;
}

#question p {
  font-size: 17px;
  font-family: 'Montserrat', sans-serif;
  margin: 0px;
  font-weight: bold;
}

#q-border-line {
  border-bottom: solid white 2px;
  width: 94px;
}

#q-button-container {
  display: flex;
  align-items: center;
  height: 50%;
  width: 100%;
  flex-direction: column;
  width: 70%;
  justify-content: flex-start;
  max-width: 800px;
  padding-top: 25px;
}

.q-button {
  background-color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  border: solid 2px white;
  color: #0397d6;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
  min-height: 50px;
  width: 190px;
  align-items: center;
}

.q-button:hover {
  background-color: rgba(255,255,255,0.4);
  color:  white;
}

.q-button:focus {
  outline: none;
}

/* footer */
#q-footer-mobile {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

#q-footer-desktop {
  display: none;
}

/* media */
@media (min-width: 1024px) {
  .question-container {
    padding: 40px;
  }

  #question p {
    font-size: 25px;
    line-height: 36px;
  }

  #q-footer-desktop {
    width: 100%;
    height: 10%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }

  #q-footer-mobile {
    display: none;
  }
}

</style>