<template>
  <div class="answer">
    <div id="a-header-container">
      <Header></Header>
    </div>
    <div id="a-main">
      <div id="a-gif-text-container">
        <div id="a-gif">
          <img src="../../assets/UN_Women_Spurning01.gif" alt="">
        </div>
        <div id="a-answer-text-container">
          <div id="a-user-answer">
            <div>
              {{ getAnswerStatus }}
            </div>
          </div>
          <div id="a-text">
            <p id="a-answer-text1">
              {{ getQuestion.answerText }}
            </p>
            <p id="a-answer-text2">
              {{ getQuestion.answerText2 }}
            </p>
          </div>
          <div id="a-border-line"></div>
          <div id="a-button-container">
            <button @click="next" class="a-button"><div>Næsta spurning</div></button>
          </div>
        </div>
      </div>
      <div id="a-footer-container">
        <footer-mobile></footer-mobile>
      </div>
    </div>
    <div id="a-footer-wide">
      <!-- <div id="a-button-container-wide">
          <button @click="next" class="a-button">Næsta spurning</button>
      </div> -->
      <div id="a-footer-container-wide">
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '../Header.vue'
import Footer from '../Footer.vue'
import FooterMobile from '../FooterMobile.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations([
      'setCurrentQuestion',
      'setIsProgressBar'
      ]),
    next() {
      this.$router.push('../Question2');
    }
  },
  components: {
    Header,
    Footer,
    FooterMobile
  },
  computed: {
    ...mapGetters([
      'getQuestion',
      'getAnswerStatus'
      ])
  },
  created() {
    this.setCurrentQuestion(0);
    this.setIsProgressBar(true);
  }
}
</script>

<style>


.answer {
  margin: 0;
  padding: 20px;
  width: 100%;
  background-color: #0397d6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 100%;
}

/* Header  */

#a-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 62px;
}

/* Main  */

#a-main {
  padding-top: 40px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
  max-width: 944px;
}

#a-gif-text-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

#a-answer-text-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#a-user-answer {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 40px 0px;
  font-size: 5.4vw;
}

#a-user-answer div {
  color: white;
  font-family: 'Montserrat', sans-serif;
}

#a-text {
  display: flex;
  align-items: center;
  text-align: left;
  color: white;
  height: 100%;
  width: 100%;
  max-width: 480px;
  font-family: 'Lato', sans-serif;
  flex-direction: column;
}

#a-answer-text1 {
  font-size: 2.3vh;
}

#a-answer-text2 {
  font-size: 2.3vh;
  font-style: italic;
}

#a-border-line {
  border-bottom: solid white 2px;
  width: 94px;
  margin: 20px 0px;
}

#a-button-container {
  display: flex;
  align-items: center;
  height: 40%;
}

.a-button {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  border: solid 2px #0397d6;
  color: white;
  /* padding: 16px 32px; */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: #0397d6;
  border-radius: 10px;
  height: 50px;
  width: 190px;
  align-items: center;
}


.a-button:hover {
  background-color: #0397d6;
  color: white;
  border: solid 2px white;
}

.a-button:focus {
  outline: none;
}

#a-gif {
  display: flex;
  align-items: center;
  justify-content: center;
}

#a-gif img {
  width: 100%
}

/* footer */

#a-footer-container {
  padding-top: 30px;
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

#a-footer-wide {
  display: none;
}

/* media */

@media (min-width: 1024px) {

  .answer {
    padding: 40px;
    height: 100%;
  }
  #a-main {
    padding-top: 40px;
    flex-direction: column;
    /* height: 70%; */
    align-items: flex-start;
  }

  #a-gif-text-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  }

  #a-gif {
    max-width: 450px;
  }

  #a-answer-text-container {
    max-width: 450px;
  }

  #a-user-answer {
    padding-top: 0px;
    padding-bottom: 20px;
  }

  #a-border-line {
    margin: 5px 0px 20px 0px;
  }

  #a-footer-container {
    display: none;
  }

  #a-footer-wide {
    /* padding-top: 40px; */
    width: 100%;
    height: 15%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }

  #a-button-container-wide {
    display: flex;
    align-items: center;
    height: 100%;
  }

  #a-footer-container-wide {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

@media (min-width: 500px) {
  #a-answer-text1 {
    font-size: 16px;
  }

  #a-answer-text2 {
    font-size: 16px;
  }

  #a-user-answer {
    font-size: 30px;
  }
}

</style>