import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import store from './store.js';

import App from './App.vue'

import Landing from './components/Landing.vue'
import StartQuiz from './components/StartQuiz.vue'

import Question1 from './components/questions/Question1.vue'
import Question2 from './components/questions/Question2.vue'
import Question3 from './components/questions/Question3.vue'
import Question4 from './components/questions/Question4.vue'
import Question5 from './components/questions/Question5.vue'
import Question6 from './components/questions/Question6.vue'
import Question7 from './components/questions/Question7.vue'
import Question8 from './components/questions/Question8.vue'
import Question9 from './components/questions/Question9.vue'

import Answer1 from './components/answers/Answer1.vue'
import Answer2 from './components/answers/Answer2.vue'
import Answer3 from './components/answers/Answer3.vue'
import Answer4 from './components/answers/Answer4.vue'
import Answer5 from './components/answers/Answer5.vue'
import Answer6 from './components/answers/Answer6.vue'
import Answer7 from './components/answers/Answer7.vue'
import Answer8 from './components/answers/Answer8.vue'
import Answer9 from './components/answers/Answer9.vue'

import FinalPage from './components/FinalPage.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: '', component: Landing },
    { path: '/Start', component: StartQuiz },
    { path: '/Question1', component: Question1 },
    { path: '/Question2', component: Question2 },
    { path: '/Question3', component: Question3 },
    { path: '/Question4', component: Question4 },
    { path: '/Question5', component: Question5 },
    { path: '/Question6', component: Question6 },
    { path: '/Question7', component: Question7 },
    { path: '/Question8', component: Question8 },
    { path: '/Question9', component: Question9 },
    { path: '/Answer1', component: Answer1 },
    { path: '/Answer2', component: Answer2 },
    { path: '/Answer3', component: Answer3 },
    { path: '/Answer4', component: Answer4 },
    { path: '/Answer5', component: Answer5 },
    { path: '/Answer6', component: Answer6 },
    { path: '/Answer7', component: Answer7 },
    { path: '/Answer8', component: Answer8 },
    { path: '/Answer9', component: Answer9 },
    { path: '/Final', component: FinalPage }
  ],
  scrollBehavior(from) {
    if (from.fullPath == "/Answer9") {
      return { left: 0, top: 0 }
    }
  }
});

const app = createApp(App)

app.use(store);
app.use(router);

router.isReady().then(function () {
  app.mount('#app');
});
