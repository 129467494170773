<template>
  <div id="app" :style="windowHeight">
    <router-view v-slot="slotProps">
      <transition name="moveUp">
        <component :is="slotProps.Component"></component>
      </transition>
    </router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data: function (){
    return {
      windowHeight: {
        height: window.innerHeight + "px"
      }
    }
  },
  methods: {
    myEventHandler() {
        this.windowHeight.height = window.innerHeight + "px";
    },
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
  },
  unmounted() {
    window.removeEventListener("resize", this.myEventHandler);
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Montserrat:wght@600&display=swap');

* {
  margin: 0;
  padding: 0;
}


#app {
  margin: 0;
  padding: 0;
  width: 100vw;
  background-color: #0397d6;
  font-family: 'Montserrat', sans-serif;
  box-sizing: border-box;
  min-height: 600px;
  min-width: 315px;
}


/* slide  */

.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 0.1s;
}

.slide-enter,
.slide-leave-to {
  opacity: 0;
  transform: translateX(-30%);
}

/* move up */

.moveUp-enter-active {
  animation: fadeIn 0.5s ease-in;
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.moveUp-leave-active {
  animation: moveUp 0.3s ease-in;
}

/* slide-scale */

.route-enter-from {

}

.route-enter-active {
  animation: slide-scale 0.4s ease-out;
}

.route-enter-to {

}

.route-leave-active {

}

/* fade */

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.3s ease-out;
}

.fade-leave-active {
  transition: opacity 0.3s ease-in;
}

@media (min-width: 1024px) {
  #app {
    min-height: 760px;
  }
}

</style>
