<template>
  <div id="footer">
    <div id="un-page">
      <a href="https://unwomen.is/verkefnin/loftslagsbreytingar/" style="text-decoration:none" target="_blank">
        <div id="unwomen-link">UN Women Ísland heimasíða</div>
      </a>
    </div>
    <div id="progress" v-if="getIsProgressBar">
      <div id="progress-count">{{ getQuestion.id }} / {{ getQuestionCount }}</div>
      <div class="healthbar">
        <div class="healthbar__value" :style="barStyles"></div>
      </div>
    </div>
    <div id="social-share">
      <Deila></Deila>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Deila from './Deila.vue'

export default {
  name: 'Footer',
  computed: {
    ...mapGetters([
      'getQuestion',
      'getQuestionCount',
      'getIsProgressBar'
    ]),
    barStyles() {
      return { width: (this.getQuestion.id / this.getQuestionCount) * 100 + '%' };
    }
  },
  components: {
    Deila
  }
}
</script>

<style scoped>

#footer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

#un-page {
  width: 30%;
}

#un-page a{
  color: white;
}

#progress {
  width: 30%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

#progress-count {
  font-size: 16px;
  color: white;
  font-family: 'Montserrat', sans-serif;
}

.healthbar {
  width: 85%;
  height: 20px;
  margin: 0px;
  background: rgba(255,255,255,0.4);
  border-radius: 8px;
}

.healthbar__value {
  background-color: white;
  width: 100%;
  height: 100%;
  border-radius: 7px;
}

#social-share{
  width: 30%;
}

#unwomen-link {
  border-bottom: 2px solid white;
  max-width: 255px;
  padding-bottom: 6px;
  text-align: center;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  font-size: 16px;
  width: 208px;
}

</style>
