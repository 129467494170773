<template>
<div id="landing-background">
  <div class="landing">
    <div id="landing-header-container">
      <Header></Header>
    </div>
    <div id="landing-main">
      <div id="landing-title">
        <div id="landing-main-title">
          HVERNIG BITNA
          <br>
          LOFTSLAGSBREYTINGAR
          <br>
          Á KONUM?
        </div>
      </div>
      <div id="landing-border-line"></div>
      <div id="landing-button-container">
        <button @click="start" class="landing-button"><div>Taktu prófið!</div></button>
      </div>
    </div>
    <div id="footer-mobile">
      <footer-mobile></footer-mobile>
    </div>
    <div id="footer-desktop">
      <Footer></Footer>
    </div>
  </div>
</div>
</template>

<script>
import Header from './Header.vue'
import FooterMobile from './FooterMobile.vue'
import Footer from './Footer.vue'

export default {
  name: 'Landing',
  methods: {
    start() {
      this.$router.push('./Question1');
    },
  },
  components: {
    Header,
    FooterMobile,
    Footer,
  },
}
</script>

<style scoped>

#landing-background {
  margin: 0px;
  padding: 0px;
  height: 100%;
  width: 100vw;
  background-image: url('../assets/01.jpg');
  background-size: cover;
  background-position: 28% 0%;
}

.landing {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

/* Header  */
#landing-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 10%;
}

/* Main  */
#landing-main {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
}

#landing-title {
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  height: 60%;
  width: 100%;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 25px;
}

#landing-main-title {
  width: 100%;
  display: flex;
  font-size: 7vw;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  line-height: 9.4vw;
}

#landing-border-line {
  border-bottom: solid white 2px;
  width: 94px;
}

#landing-button-container {
  padding-top: 25px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 40%;
}

.landing-button {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  border: solid 2px #0397d6;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: #0397d6;
  color: white;
  border-radius: 10px;
  height: 50px;
  width: 190px;
  align-items: center;
}

.landing-button:hover {
  background-color: white;
  border: solid 2px white;
  color:  #0397d6;
}

.landing-button:focus {
  outline: none;
}

#deila-mobile {
  width: 50%;
}

/* footer */
#footer-mobile {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

#footer-desktop {
  display: none;
}


/* media queries*/

@media (min-width: 1024px) {
  .landing {
    padding: 40px;
  }

  #footer-desktop {
    width: 100%;
    height: 20%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }

  #footer-mobile,
  #deila-mobile {
    display: none;
  }

}

/* font size on title never larger than 48px */
@media (min-width: 640px) {

  #landing-main-title {
    font-size: 48px;
    line-height: 58px;
  }
}



</style>
