<template>
  <div class="start-landing">
    <div id="start-header-container">
      <Header></Header>
    </div>
    <div id="start-main">
      <div id="start-title">
        <p>
          <span>
            Loftslagsbreytingar hafa áhrif á okkur öll. En þær bitna á okkur á ólíkan hátt eftir því hvar við búum, stöðu okkar og kyni.
          </span>
          <span>
            Loftslagsbreytingar leggjast þyngra á þau sem búa í fátækari ríkjum heims en okkur sem búum á Íslandi, og hafa ríkari áhrif á líf kvenna en karla. Konur sem búa á dreifbýlum svæðum fátækari ríkja eru í sérstaklega slæmri stöðu vegna loftslagsbreytinga og konur eru allt að 14 sinnum líklegri til að deyja í náttúruhamförum en karlar.
          </span>
          <span>
            Þessar staðreyndir hafa fengið litla athygli en skipta miklu máli í stóra samhenginu. Útrýming fátæktar og menntun kvenna eru grunnforsendur fyrir því að hægt sé að snúa við þeirri þróun sem átt hefur sér stað í loftslagsmálum undanfarna áratugi. Verði ekki tekið mið af þessum veigamiklu þáttum, munu aðrar framfarir á sviði loftslagsmála ekki duga til.
          </span>
          <span>
            Loftslagsváin er sameiginlegur vandi allra jarðarbúa og við þurfum öll að mynda samstöðu til að og sporna við afleiðingum loftslagsbreytinga!
          </span>
          <span>
            Taktu prófið og kynntu þér hvernig loftslagsbreytingar af manna völdum hafa ólík áhrif á okkur eftir kyni og búsetu.
          </span>
        </p>
      </div>
      <div id="start-border-line"></div>
      <div id="start-button-container">
        <button @click="start" class="start-button"><div>Taktu prófið!</div></button>
      </div>
      <div id="start-footer-container">
        <footer-mobile></footer-mobile>
      </div>
      <div id="start-footer-wide">
        <div id="start-button-container-wide">
            <button @click="start" class="start-button">Taktu prófið!</button>
        </div>
        <div id="start-footer-container-wide">
          <Footer></Footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header.vue'
import FooterMobile from './FooterMobile.vue'
import Footer from './Footer.vue'

export default {
  methods: {
    start() {
      this.$router.push('./Question1');
    }
  },
  components: {
    Header,
    FooterMobile,
    Footer
  }
}
</script>

<style scoped>

.start-landing {
  margin: 0;
  padding: 20px;
  min-height: 100%;
  width: 100vw;
  background-color: #0397d6;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Header  */
#start-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 62px;
}

/* Main  */
#start-main {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  align-items: center;
}

#start-title {
  padding-top: 40px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: white;
  height: 100%;
  width: 100%;
  max-width: 850px;
}

#start-title p {
  font-size: 4vw;
  font-family: 'Lato', sans-serif;
  text-align: left;
  margin-bottom: 0px;
}

#start-title p span {
  display: inline-block;
  padding-bottom: 10px;
}

#start-border-line {
  padding-top: 20px;
  border-bottom: solid white 2px;
  width: 94px;
}

#start-button-container {
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 40%;
  padding-bottom: 40px;
}

.start-button {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  border: solid 2px #0397d6;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  background-color: white;
  color: #0397d6;
  border-radius: 10px;
  height: 50px;
  width: 190px;
  align-items: center;
}

.start-button:hover {
  background-color: #0397d6;
  color: white;
  border: solid 2px white;
}

.start-button:focus {
  outline: none;
}

/* footer */
#start-footer-container {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
}

#start-footer-wide {
  display: none;
}

@media (min-width: 1024px) {

  .start-landing {
    padding: 40px;
  }

  #start-footer-container,
  #start-button-container {
    display: none;
  }

  #start-footer-wide {
    width: 100%;
    display: flex;
    align-items: center;
    height: 20vh;
    flex-direction: column;
  }

  #start-button-container-wide {
    padding-top: 30px;
    display: flex;
    align-items: center;
    height: 40%;
  }

  #start-footer-container-wide {
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
  }

  #start-border-line {
    padding-top: 15px;
  }
}

@media (min-width: 500px) {
  #start-title p {
    font-size: 20px;
  }
}

</style>