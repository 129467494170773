import { createStore } from 'vuex';

const store = createStore({
  state() {
    return {
      questions: [
        {
          id: 1,
          question: "Um 16 milljónir flúðu heimili sín árið 2018 vegna náttúruhamfara á borð við storma, flóð, skógarelda og ofsahita.",
          options: ["Rétt", "Rangt"],
          qtitle: "Náttúruhamfarir",
          atitle: "Það er því miður rétt",
          answerIndex: 0,
          answerText: "Náttúruhamfarir og ofsaveður verða æ tíðari með breyttu veðurfari. Um 28 milljónir neyddust til að yfirgefa heimili sín árið 2018 sökum  hættuástands, þar af 16,1 milljón vegna náttúruhamfara á borð við storma, flóð, þurrka, skógarelda, skriður og ofsahita. Í mörgum tilfellum flýr fólk til landa þar sem fyrir er ótryggt ástand eða veðurfar og lendir aftur á flótta.",
          answerText2: "Í þrjátíu löndum víðsvegar um heiminn veitir UN Women konum og stúlkum öryggi, hagnýtt nám og nauðsynjavörur. Þá tryggir UN Women að tekið sé mið af þörfum kvenna við gerð viðbragðsáætlana og neyðaraðstoðar.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 2,
          question: "Með loftslagsbreytingum færast vopnuð átök í vöxt.",
          options: ["Rétt", "Rangt"],
          qtitle: "Stríð",
          atitle: "Það er því miður rétt",
          answerIndex: 0,
          answerText: "Sárafátækt og ójöfnuður eru einn helsti hvati borgarastríða. Loftslagsbreytingar hafa valdið átökum og stríðum víða um heim, einkum þó í Mið-Austurlöndum og í Afríku. Ríki með veika innviði og ólýðræðislega stjórnarhætti eru útsettari fyrir innri átökum.",
          answerText2: "UN Women vinnur að því að tryggja að raddir kvenna heyrist í friðar- og sáttaumleitunum og starfrækir um 70 griðastaði í flóttamannabúðum fyrir konur og börn þeirra þar sem þær hljóta sálræna aðstoð, menntun og atvinnutækifæri, daggæslu fyrir börn sín, valdeflingu og öryggi. Þá dreifir UN Women nauðsynjavörum til kvenna á átakasvæðum.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 3,
          question: "Hvaða áhrif hafa loftslagsbreytingar á borð við þurrka, flóð, uppskerubresti og ofsaveður haft á líf kvenna og stúlkna?",
          options: ["Aukin tíðni þvingaðra barnahjónabanda", "Aukin tíðni mæðra- og ungbarnadauða", "Auknar líkur á lífshættulegum sjúkdómum", "Allt ofantalið"],
          qtitle: "Áhrif á líf kvenna",
          atitle: "Allt ofantalið",
          answerIndex: 3,
          answerText: "Ofbeldi gegn konum, mansal, mæðradauði, þvinguð barnahjónabönd, sultur og vannæring eru allt vandamál sem færast í aukana samhliða langvarandi þurrkum eða flóðum sem rekja má til loftslagsbreytinga. Þá eru stúlkur  gjarnan teknar úr skóla svo þær geti aðstoðað við fæðuöflun.",
          answerText2: "UN Women beitir sér fyrir heimsmarkmiði 1, útrýmingu fátæktar, með því að auka aðgengi kvenna að lántöku, mörkuðum, landi og menntun. Víða um heim býður UN Women konum í landbúnaði og matvælaræktun upp á frumkvöðlaþjálfun, hagnýt viðskiptanámskeið og aðstoð við að byggja upp eigin rekstur.  Að auki þrýstir UN Women á stjórnvöld að tryggja konum erfðaréttog aukin réttindi á vinnumarkaði.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 4,
          question: "Í 25 ríkjum sunnan Sahara eyða konur samanlagt um 16 milljónum klukkustunda á dag í leit að hreinu neysluvatni handa fjölskyldum sínum.",
          options: ["Rétt", "Rangt"],
          qtitle: "Neysluvatn",
          atitle: "Það er því miður rétt",
          answerIndex: 0,
          answerText: "Konur eyða samanlagt um 16 milljónum klukkustunda á dag í leit að hreinu neysluvatni, en til samanburðar verja karlmenn 6 milljónum klukkustunda á dag í sama verkefni og börn samtals 4 milljónum klukkustunda. Með auknum þurrkum og landeyðingu þurfa konur að ganga lengri vegalengdir í leit að vatni handa fjölskyldum sínum, sem lengir ekki aðeins vinnudag þeirra, heldur eykur líkur á hitaörmögnun. Rannsóknir sýna að um 2,1 milljarður jarðarbúa hafi ekki aðgang að hreinu neysluvatni.",
          answerText2: "UN Women vinnur að því að auka aðgengi að neysluvatni, tryggja konum viðunandi salernis- og hreinlætisaðstöðu, og þjálfa starfsfólk til að bregðast við þurrkum.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 5,
          question: "Hversu mörg dauðsföll má rekja árlega til ófullnægjandi loftgæða á heimilum vegna heilsuspillandi orkugjafa?",
          options: ["500 þúsund", "1,5 milljónir", "2,8 milljónir", "3,8 milljónir"],
          qtitle: "Loftgæði",
          atitle: "3,8 milljónir",
          answerIndex: 3,
          answerText: "Um þrír milljarðar jarðarbúa notast enn við óumhverfisvænt eldsneyti á heimilum sínum á borð við kol, við, tað og kerósín, og elda yfir opnum eldstóm. Rekja má um fjórar milljónir dauðsfalla árlega til reyk- og kolefniseitrunar. Meirihluti þeirra eru konur og börn, enda verja þau mestum tíma í eldhúsunum.",
          answerText2: "UN Women starfrækir verkefni sem veitir konum umhverfisvænar eldavélará svæðum sem hafa orðið illa úti vegna þurrka og landeyðingar, t.d. í Gana. Slíkar eldavélar hafa jákvæð áhrif á heilsu kvenna og sparar þeim tíma sem annars færi í að afla eldiviðar. Sumar konurnar hafa séð sóknarfæri í þessum nýju eldavélum og sett á laggirnar eigin veitingaþjónustu sem gerir þeim kleift að sjá fyrir fjölskyldum sínum.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 6,
          question: "Á næstu áratugum er talið að dauðsföllum af völdum loftslagsbreytinga muni fjölga um:",
          options: ["8.500 dauðsföll á ári", "87.000 dauðsföll á ári", "160.000 dauðsföll á ári", "250.000 dauðsföll á ári"],
          qtitle: "Fjölgun dauðsfalla",
          atitle: "250 þúsund dauðsföll á ári",
          answerIndex: 3,
          answerText: "Með breyttu veðurfari af völdum loftslagsbreytinga má búast við því að dauðsföllum vegna vannæringar, malaríu, hitaörmögnunar og náttúruhamfara fjölgi um 250.000 á ári hverju á tímabilinu 2030 til 2050.",
          answerText2: "UN Women þjálfar konur á viðkvæmum svæðum í almannavörnum og gerð viðbragðsáætlana á tímum náttúruhamfara, þar á meðal á Fiji og í Bangladesh. Nú þegar hafa um 19.000 konur í Bangladesh lokið þeirri þjálfun. Þá hljóta konur og stúlkur víða um heim fræðslu í náttúruvernd og sjálfbærni sjávar á vegum UN Women.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 7,
          question: "Á tímum neyðar í kjölfar hamfara og átaka fjölgar tilfellum þvingaðara barnahjónabanda umtalsvert. Hversu margar barnungar stúlkur eru þvingaðar í hjónaband á hverri mínútu  á heimsvísu?",
          options: ["3 stúlkur", "14 stúlkur", "23 stúlkur", "56 stúlkur"],
          qtitle: "Barnahjónabönd",
          atitle: "23 stúlkur",
          answerIndex: 2,
          answerText: "Staðreyndin er því miður sú að 23 barnungar stúlkur eru þvingaðar í hjónaband á hverri mínútu, eða um 12 milljónir stúlkna á ári hverju. Þessi skaðlegi siður hefur víðtæk áhrif á líf stúlkna, sem neyðast til að hætta í skóla og eru því líklegri til að festast í viðjum fátæktar. Barnungar stúlkur eru talsvertlíklegri til að deyja af barnsförum þar sem óþroskaðir líkamar þeirra eru ekki búnir undir meðgöngu og fæðingu. Mæðradauði er ein helsta dánarorsök stúlkna á aldrinum 15-19 ára.",
          answerText2: "UN Women vinnur að upprætingu þvingaðra barnahjónabanda með því að þrýsta á stjórnvöld að festa í lög hærri giftingaraldur, þjálfa þorpshöfðingja og mæðrahópa í að veita fræðslu um skaðlegar afleiðingar siðarins og styðja stúlkur sem leystar hafa verið úr slíku hjónabandi aftur til náms.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 8,
          question: "Konur eru 80% þeirra sem starfa í fataiðnaði og flestar þeirra búa undir fátækramörkum. Hvaða áhrif hefur fataiðnaðurinn á samfélög og umhverfið?",
          options: ["Litun á vefnaðarvöru hefur gríðarlega mengandi áhrif á vatnsforða fátækari ríkja", "Konur og börn vinna við óviðunandi aðstæður og njóta engra réttinda", "Konur innan fataiðnaðarins þurfa iðulega að þola ofbeldi og ómannúðlega meðferð við störf sín", "Allt ofantalið"],
          qtitle: "Fataiðnaðurinn",
          atitle: "Allt ofantalið",
          answerIndex: 3,
          answerText: "Fataiðnaðurinn hefur lengi verið gagnrýndur fyrir meðferð sína á vinnuafli og umhverfissóðaskap. Verksmiðjurnar eru gjarnan illa búnar, starfsumhverfi fólks er hættulegt, vinnudagar langir og laun takmörkuð.",
          answerText2: "UN Women vinnur náið með ýmsum framleiðendum að því að styðja við framgang ungra kvenna sem starfa innan fataiðnaðarins. Leiðtogaþjálfun starfskvenna er einn þáttur í verkefninu sem eykur möguleika þeirra á að vinna sig upp innan fyrirtækjanna. Með fræðslu og vitundarvakningu vinnur UN Women að því að tryggja starfskonum matarhlé, salernispásur og mannsæmandi laun.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
        {
          id: 9,
          question: "Menntun kvenna er talin vera  grunnforsenda þess að raunverulegur árangur náist í baráttunni gegn loftslagsbreytingum. Í dag er hlutfall stúlkna sem ljúka grunnskóla aðeins um:",
          options: ["1/3 stúlkna heimsins", "2/3 stúlkna heimsins"],
          qtitle: "Menntun kvenna",
          atitle: "1/3 stúlkna heimsins",
          answerIndex: 0,
          answerText: "Aðeins þriðjungur stúlkna lýkur grunnskólanámi í dag. Rannsóknir á afleiðingum náttúruhamfara í 125 löndum víða um heim hafa sýnt að í löndum þar sem konur hafa hlotið menntun fram yfir fyrstu fimm bekki grunnskóla, er tíðni dauðsfalla töluvert lægri.",
          answerText2: "UN Women starfrækir verkefni sem styðja konur og stúlkur til náms, enda er menntun kvenna og stúlkna ein af lykilforsendum þess að jöfnuður náist og fátækt verði útrýmt. UN Women styður til dæmis stúlkur sem leystar hafa verið úr hjónabandi á barnsaldri styrk til skólagöngu og veita konum á flótta hagnýtt nám víða um heim.",
          answerLink: 'https://unwomen.is/',
          score: 0
        },
      ],
      currentQuestion: 0,
      isProgressBar: false,
      result: [
        {
          title: "0 - 22%",
          text: "Nú ert þú margs fróðari um loftslagsbreytingar og áhrif þeirra á líf kvenna. Við treystum því að þú deilir áfram þessari mikilvægu þekkingu því við þurfum fleira fólk eins og þig!",
          percent: ""
        },
        {
          title: "33 - 44%",
          text: "Þú veist ýmislegt um loftslagsbreytingar og áhrif þeirra á líf kvenna. Nú hefur þú aflað þér enn meiri þekkingar með því að taka þetta próf. Við treystum því að þú deilir henni áfram því við þurfum fleira fólk eins og þig!",
          percent: ""
        },
        {
          title: "56 - 67%",
          text: "Þú hefur dágóða þekkingu á loftslagsbreytingum og áhrifum þeirra á líf kvenna. Við treystum því að þú deilir henni áfram því við þurfum fleira fólk eins og þig!",
          percent: ""
        },
        {
          title: "78%, 89%, 100%",
          text: "Þekking þín á loftslagsbreytingum og áhrifum þeirra á líf kvenna er framúrskarandi! Við treystum því að þú deilir henni áfram því við þurfum fleira fólk eins og þig.",
          percent: ""
        },
      ],
      finalScore: 0
    };
  },
  getters: {
    getQuestion(state) {
      var currentQuestion = state.questions[state.currentQuestion];
      return currentQuestion;
    },
    getQuestionCount: state => {
      return state.questions.length;
    },
    getScore: state => {
      return state.score;
    },
    getFinalResult: state => {
      var questions = state.questions;

      for (var i = 0; i < questions.length; i++) {
        state.finalScore += questions[i].score
      }

      if (state.finalScore < 3) {
        if (state.finalScore == 0) {
          state.result[0].percent = "0%"
        }
        else if (state.finalScore == 1) {
          state.result[0].percent = "11%"
        }
        else if (state.finalScore == 2) {
          state.result[0].percent = "22%"
        }
        return state.result[0];
      }
      else if (state.finalScore < 5) {
        if (state.finalScore == 3) {
          state.result[1].percent = "33%"
        }
        else if (state.finalScore == 4) {
          state.result[1].percent = "44%"
        }
        return state.result[1];
      }
      else if (state.finalScore < 7) {
        if (state.finalScore == 5) {
          state.result[2].percent = "56%"
        }
        else if (state.finalScore == 6) {
          state.result[2].percent = "67%"
        }
        return state.result[2];
      }
      else if (state.finalScore < 10) {
        if (state.finalScore == 7) {
          state.result[3].percent = "78%"
        }
        else if (state.finalScore == 8) {
          state.result[3].percent = "89%"
        }
        else if (state.finalScore == 9) {
          state.result[3].percent = "100%"
        }
        return state.result[3];
      }
      else {
        return state.result[0];
      }
    },
    getFinalScore(state) {
      return state.finalScore;
    },
    getCurrentQuestion(state) {
      return state.currentQuestion;
    },
    getIsProgressBar(state) {
      return state.isProgressBar;
    },
    getAnswerStatus(state) {
      if (state.questions[state.currentQuestion].score == 1) {
        return "Rétt svar!";
      }
      else {
        return "Rangt svar.";
      }
    },
  },
  mutations: {
    setScore: (state, payload) => {
      var question = state.questions[state.currentQuestion];

      if (payload == question.answerIndex) {
        state.questions[state.currentQuestion].score = 1;
      }
      else {
        state.questions[state.currentQuestion].score = 0;
      }
    },
    setCurrentQuestion: (state, payload) => {
      state.currentQuestion = payload;
    },
    incrementQuestion: (state, payload) => {
      state.currentQuestion = payload;
    },
    setIsProgressBar: (state, payload) => {
      state.isProgressBar = payload;
    }
  }
});

export default store;