<template>
  <div id="header-container">
    <div id="logo">
      <a href="http://loftslagsbreytingar.unwomen.is">
        <img src="../assets/UN-logo.png" alt="U.N. Logo">
      </a>
    </div>
    <div id="counter" v-if="getIsProgressBar">
      <div>{{ getQuestion.id }} / {{ getQuestionCount }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters([
      'getQuestion',
      'getQuestionCount',
      'getIsProgressBar'
      ])
  }
}
</script>

<style scoped>

#header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

#logo {
  height: 100%
}

#logo a{
  display: inline-block;
  height: 90%;
}

img {
  height: 100%;
}

#counter {
  display: flex;
  align-items: flex-start;
  color: white;
  height: 100%;
  font-size: 5.5vw;
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 768px) {
  #counter {
    display: none;
  }

  #logo a{
    height: 70px;
  }
}

</style>
