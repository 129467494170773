<template>
  <div class="question-container">
    <div id="q-header-container">
      <Header></Header>
    </div>
    <div id="q-main">
      <div id="question">
        <p>
          {{ getQuestion.question }}
        </p>
      </div>
      <div id="q-border-line"></div>
      <div id="q-button-container">
        <button v-for="(option, index) in getQuestion.options" class="q-button" @click="score(index)" v-bind:key="index">
          <span> {{ option }} </span>
        </button>
      </div>
    </div>
    <div id="q-footer-mobile">
      <footer-mobile></footer-mobile>
    </div>
    <div id="q-footer-desktop">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Header from '../Header.vue'
import FooterMobile from '../FooterMobile.vue'
import Footer from '../Footer.vue'
import { mapGetters } from 'vuex';
import { mapMutations } from 'vuex';

export default {
  methods: {
    ...mapMutations([
      'setScore',
      'setCurrentQuestion',
      'setIsProgressBar'
    ]),
    score(index) {
      this.setScore(index);
      this.$router.push('../Answer6');
    }
  },
  components: {
    Header,
    FooterMobile,
    Footer
  },
  computed: {
    ...mapGetters(['getQuestion'])
  },
  created() {
    this.setCurrentQuestion(5);
    this.setIsProgressBar(true);
  }
}
</script>

<style scoped>

.question-container {
  background-image: url('../../assets/07.jpg');
  background-size: cover;
  background-position: 35% 0%;
}

.q-button {
    width: 250px;
}

#question {
    height: 25%;
}

#q-main {
  justify-content: center;
}

@media (min-width: 1024px) {
  #q-main {
    justify-content: center;
  }

  #q-button-container {
    width: 400px;
  }
}


</style>